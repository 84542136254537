.draft-badge .MuiBadge-badge {
  right: -10px;
  background-color: RGB(var(--px_color_badge_1));
  color: RGB(var(--px_color_text_on_badge));
}

.scheduled-badge .MuiBadge-badge {
  right: -10px;
  background-color: RGB(var(--px_color_badge_4));
  color: RGB(var(--px_color_text_on_badge));
}

.offering-badge .MuiBadge-badge {
  right: -10px;
  background-color: RGB(var(--px_color_badge_2));
  color: RGB(var(--px_color_text_on_badge));
}

.shipping-badge .MuiBadge-badge {
  right: -10px;
  background-color: RGB(var(--px_color_badge_3));
  color: RGB(var(--px_color_text_on_badge));
}
.negotiating-badge .MuiBadge-badge {
  right: -10px;
  background-color: RGB(var(--px_color_badge_6));
  color: RGB(var(--px_color_text_on_badge));
}
.finalizing-badge .MuiBadge-badge {
  right: -10px;
  background-color: RGB(var(--px_color_badge_3));
  color: RGB(var(--px_color_text_on_badge));
}

.multiple-selected {
  margin-right: 10px;
  #Color {
    fill: white;
  }
}

.px-itemsetup-select-panel {
  // padding-left: 10px;
  align-items: center;
  justify-content: center;
}

.text-center {
  text-align: center;
}

.px-itemsetup-grid {
  margin-bottom: 64px !important;
  div.ag-row-level-0.ag-row-group {
    span.ag-group-value {
      align-self: center;
    }
    // span.ag-row-group {
    //   padding-left: 10px;
    // }
    span.ag-group-expanded {
      display: none;
    }
  }
  .ag-theme-alpine .ag-header-cell[col-id='ag-Grid-AutoColumn'] .ag-header-cell-label {
    padding-left: 30px !important;
  }

  .px-itemsetup-date-field .MuiOutlinedInput-input {
    width: 220px !important;
    padding: 10px 0px 10px 10px !important;
  }
  .px-itemsetup-date-field :hover {
    cursor: pointer;
  }

  .px-itemsetup-draft,
  .px-itemsetup-shipping,
  .px-itemsetup-scheduled,
  .px-itemsetup-offering,
  .px-itemsetup-negotiating,
  .px-itemsetup-finalizing {
    color: white;
    padding: 5px 10px;
    min-width: 100px;
    width: 100px;
    max-width: fit-content;
    border-radius: 25px;
  }

  .px-itemsetup-draft {
    background-color: RGB(var(--px_color_badge_1));
  }

  .px-itemsetup-shipping {
    background-color: RGB(var(--px_color_badge_3));
  }

  .px-itemsetup-scheduled {
    background-color: RGB(var(--px_color_badge_4));
  }
  .px-itemsetup-offering {
    background-color: RGB(var(--px_color_badge_2));
  }
  .px-itemsetup-negotiating {
    background-color: RGB(24, 64, 12);
  }
  .px-itemsetup-finalizing {
    background-color: RGB(44, 11, 208);
  }
  .ag-row-group.ag-row-level-0 {
    background-color: RGBA(var(--px_color_info), var(--px_alpha_light));
  }
  .ag-header-cell-sortable {
    cursor: default !important;
  }
  .px-po-item-cell .ag-group-checkbox .ag-selection-checkbox.ag-hidden {
    visibility: hidden;
    display: block !important;
  }
  .ag-selection-checkbox {
    padding-left: 2px;
  }
  .ag-theme-alpine .ag-ltr .ag-row-level-1 .ag-row-group-leaf-indent {
    margin-left: 0px !important;
  }
}
.scheduleDialog {
  .MuiPaper-root.MuiDialog-paper {
    min-width: 600px !important;
  }
  .scheduleDialogContent {
    p {
      display: grid;
      grid-template-columns: 130px auto;
    }
  }
  .MuiDialogActions-root {
    gap: 16px;
  }
}

.time-range-link,
.time-range-link:visited {
  color: RGB(var(--px_color_primary));
  text-decoration: none;
}
.time-range-link:hover {
  cursor: pointer;
}
.margin-top-half-1 {
  margin-top: 5px;
}

.offering-times {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
