.MuiCard-root {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 8px 0px !important;
  border-radius: 10px !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}
// .delete-popover{
//     height: 100%;
//     padding: 10px 20px 10px;

//     .delete-btn{
//         display: flex;
//         align-items: flex-start;
//         gap: 10px;
//         padding-top: 10px;
//     }
// }
.take-all-textField :hover {
  cursor: pointer;
}
